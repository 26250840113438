<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="BoxIcon" />
          <span>Makine</span>
        </template>
        <b-card>
          <b-card-text>
            <b-row>
              <b-col>
                <b-form-group label="Makine Kartı Bul">
                  <b-input-group>
                    <b-form-input
                      v-model="machineKeyword"
                      placeholder="Makine Kodu, Proje Kodu, Müşteri Adı..."
                    />
                    <b-input-group-append>
                      <b-button
                        variant="outline-primary"
                        @click="searchMachine"
                      >
                        <FeatherIcon icon="SearchIcon" />
                        Bul
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
        <b-card v-if="machineSelected">
          <b-card-title>
            {{ machine.company }}
            <small>{{ machine.customer_address }}</small>
          </b-card-title>
          <b-card-text>
            <b-list-group>
              <b-list-group-item>
                <div class="font-weight-bold text-primary">
                  Makine Kodu
                </div>
                {{ machine.mcode }}
              </b-list-group-item>
              <b-list-group-item>
                <div class="font-weight-bold text-primary">
                  Proje Numarası
                </div>
                {{ machine.project }}
              </b-list-group-item>
              <b-list-group-item>
                <div class="font-weight-bold text-primary">
                  Fabrika / Seri No
                </div>
                {{ machine.serial }}
              </b-list-group-item>
              <b-list-group-item>
                <div class="font-weight-bold text-primary">
                  Kurulum Yılı
                </div>
                {{ machine.syear }}
              </b-list-group-item>
              <b-list-group-item>
                <div class="font-weight-bold text-primary">
                  Makine Tipi
                </div>
                {{ machine.machine_type }}
              </b-list-group-item>
              <b-list-group-item>
                <div class="font-weight-bold text-primary">
                  Makine Modeli
                </div>
                {{ machine.machine_model }}
              </b-list-group-item>
              <b-list-group-item>
                <div class="font-weight-bold text-primary">
                  Temizleme Teknolojisi
                </div>
                {{ machine.machine_cleaning }}
              </b-list-group-item>
              <b-list-group-item>
                <div class="font-weight-bold text-primary">
                  Makine Durumu
                </div>
                {{ machine.machine_status }}
              </b-list-group-item>
            </b-list-group>
            <div class="text-center mt-2">
              <b-button
                variant="primary"
                @click="changeMachine"
              >
                <FeatherIcon icon="CheckCircleIcon" />
                Makine Değiştir
              </b-button>
            </div>
          </b-card-text>
        </b-card>
        <b-row v-if="!machineSelected">
          <b-col
            v-for="(machine,mkey) in machines"
            :key="mkey"
            xs="12"
            md="6"
            lg="4"
          >
            <b-card>
              <b-card-title>
                {{ machine.company }}
                <small>{{ machine.customer_address }}</small>
              </b-card-title>
              <b-card-text>
                <b-list-group>
                  <b-list-group-item>
                    <div class="font-weight-bold text-primary">
                      Makine Kodu
                    </div>
                    {{ machine.mcode }}
                  </b-list-group-item>
                  <b-list-group-item>
                    <div class="font-weight-bold text-primary">
                      Proje Numarası
                    </div>
                    {{ machine.project }}
                  </b-list-group-item>
                  <b-list-group-item>
                    <div class="font-weight-bold text-primary">
                      Fabrika / Seri No
                    </div>
                    {{ machine.serial }}
                  </b-list-group-item>
                  <b-list-group-item>
                    <div class="font-weight-bold text-primary">
                      Kurulum Yılı
                    </div>
                    {{ machine.syear }}
                  </b-list-group-item>
                  <b-list-group-item>
                    <div class="font-weight-bold text-primary">
                      Makine Tipi
                    </div>
                    {{ machine.machine_type }}
                  </b-list-group-item>
                  <b-list-group-item>
                    <div class="font-weight-bold text-primary">
                      Makine Modeli
                    </div>
                    {{ machine.machine_model }}
                  </b-list-group-item>
                  <b-list-group-item>
                    <div class="font-weight-bold text-primary">
                      Temizleme Teknolojisi
                    </div>
                    {{ machine.machine_cleaning }}
                  </b-list-group-item>
                  <b-list-group-item>
                    <div class="font-weight-bold text-primary">
                      Makine Durumu
                    </div>
                    {{ machine.machine_status }}
                  </b-list-group-item>
                </b-list-group>
                <div class="text-center mt-2">
                  <b-button
                    variant="primary"
                    @click="getMachine(machine.id)"
                  >
                    <FeatherIcon icon="CheckCircleIcon" />
                    Makine Seç
                  </b-button>
                </div>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab :disabled="!formData.id_machines">
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>Servis</span>
        </template>
        <b-card>
          <b-card-title>Çalışma Zamanı</b-card-title>
          <b-card-text>
            <b-row
              v-for="(work,work_key) in worksData"
              :key="work_key"
            >
              <b-col>
                <b-form-group label="İşe Başlama">
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-button
                        variant="primary"
                        @click="removeWorkLines(work_key)"
                      >
                        <FeatherIcon icon="XCircleIcon" />
                      </b-button>
                    </b-input-group-prepend>
                    <flat-pickr
                      v-model="work.sdate"
                      class="form-control"
                      placeholder="Seçiniz"
                      :config="flatPickrConfig"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="İşi Bitirme">
                  <flat-pickr
                    v-model="work.edate"
                    class="form-control"
                    placeholder="Seçiniz"
                    :config="flatPickrConfig"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col cols="12">
                <b-button
                  variant="success"
                  @click="addWorkLine"
                >
                  <FeatherIcon icon="PlusCircleIcon" />
                  Çalışma Ekle
                </b-button>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
        <b-card>
          <b-card-text>
            <b-row>
              <b-col
                xs="12"
                md="6"
              >
                <b-form-group label="İlgili Kişi">
                  <b-form-input
                    v-model="formData.related_person"
                    placeholder="İlgili Kişi"
                  />
                </b-form-group>
              </b-col>
              <b-col
                xs="12"
                md="6"
              >
                <b-form-group label="İlgili Telefon">
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      TR (+90)
                    </b-input-group-prepend>
                    <cleave
                      id="phone"
                      v-model="formData.related_phone"
                      class="form-control"
                      :raw="false"
                      :options="options.phone"
                      placeholder="İlgili Telefon"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Ücretlendirme">
                  <v-select
                    v-model="invoiced_status"
                    placeholder="Seçiniz"
                    label="title"
                    :options="invoicedStatuses"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Çalışma Sınıfı">
                  <v-select
                    v-model="work_type"
                    placeholder="Seçiniz"
                    label="title"
                    :options="workTypes"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="İş">
                  <v-select
                    v-model="job"
                    placeholder="Seçiniz"
                    label="title"
                    :options="jobs"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="İş Durumu">
                  <v-select
                    v-model="finishedStatus"
                    placeholder="Seçiniz"
                    label="title"
                    :options="finishedStatuses"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Yapılan İşler / Kullanılan Malzemeler / Müşteri ile Mutabakat ">
                  <b-form-textarea
                    v-model="formData.notes"
                    placeholder="Yapılan İşler / Kullanılan Malzemeler / Müşteri ile Mutabakat"
                    rows="15"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-tab>
      <b-tab :disabled="!formData.id_machines">
        <template #title>
          <feather-icon icon="MapIcon" />
          <span>Seyahat</span>
        </template>
        <b-card>
          <b-card-title>Gidiş</b-card-title>
          <b-card-text>
            <b-row>
              <b-col>
                <b-form-group label="Çıkış İl">
                  <v-select
                    v-model="city_out1"
                    placeholder="Seçiniz"
                    :options="cities"
                    label="title"
                  />
                </b-form-group>
                <b-form-group label="Varış İl">
                  <v-select
                    v-model="city_out2"
                    placeholder="Seçiniz"
                    :options="cities"
                    label="title"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Çıkış İlçe">
                  <v-select
                    v-model="district_out1"
                    placeholder="Seçiniz"
                    :options="districtsOut1"
                    label="title"
                    :disabled="districtsOut1.length === 0"
                  />
                </b-form-group>
                <b-form-group label="Varış İlçe">
                  <v-select
                    v-model="district_out2"
                    placeholder="Seçiniz"
                    :options="districtsOut2"
                    label="title"
                    :disabled="districtsOut2.length === 0"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Çıkış Tarih Saati">
                  <flat-pickr
                    v-model="formData.sdatetime_out"
                    class="form-control"
                    placeholder="Seçiniz"
                    :config="flatPickrConfig"
                  />
                </b-form-group>
                <b-form-group label="Varış Tarih Saati">
                  <flat-pickr
                    v-model="formData.edatetime_out"
                    class="form-control"
                    placeholder="Seçiniz"
                    :config="flatPickrConfig"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
        <b-card>
          <b-card-title>Dönüş</b-card-title>
          <b-card-text>
            <b-row>
              <b-col>
                <b-form-group label="Çıkış İl">
                  <v-select
                    v-model="city_return1"
                    placeholder="Seçiniz"
                    :options="cities"
                    label="title"
                  />
                </b-form-group>
                <b-form-group label="Varış İl">
                  <v-select
                    v-model="city_return2"
                    placeholder="Seçiniz"
                    :options="cities"
                    label="title"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Çıkış İlçe">
                  <v-select
                    v-model="district_return1"
                    placeholder="Seçiniz"
                    :options="districtsReturn1"
                    label="title"
                    :disabled="districtsReturn1.length === 0"
                  />
                </b-form-group>
                <b-form-group label="Varış İlçe">
                  <v-select
                    v-model="district_return2"
                    placeholder="Seçiniz"
                    :options="districtsReturn2"
                    label="title"
                    :disabled="districtsReturn2.length === 0"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Çıkış Tarih Saati">
                  <flat-pickr
                    v-model="formData.sdatetime_return"
                    class="form-control"
                    placeholder="Seçiniz"
                    :config="flatPickrConfig"
                  />
                </b-form-group>
                <b-form-group label="Varış Tarih Saati">
                  <flat-pickr
                    v-model="formData.edatetime_return"
                    class="form-control"
                    placeholder="Seçiniz"
                    :config="flatPickrConfig"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-tab>
      <b-tab :disabled="!formData.id_machines">
        <template #title>
          <feather-icon icon="EyeIcon" />
          <span>Önizleme & Kayıt</span>
        </template>
        <b-card>
          <b-card-title>Müşteri Kartı</b-card-title>
          <b-card-text>
            <customer-card />
          </b-card-text>
        </b-card>
        <b-card>
          <b-card-title>Makine Kartı</b-card-title>
          <b-card-text>
            <machine-card />
          </b-card-text>
        </b-card>
        <b-card>
          <b-card-title>Servis Bakım</b-card-title>
          <b-card-text>
            <service-card
              :service-data="formData"
              :works-data="worksData"
            />
          </b-card-text>
        </b-card>
        <b-card>
          <b-card-title>Seyahat</b-card-title>
          <b-card-text>
            <travel-card :travel-data="formData" />
          </b-card-text>
        </b-card>
        <div class="text-center">
          <b-button
            variant="primary"
            :disabled="submitStatus"
            @click="submitForm"
          >
            <FeatherIcon icon="CheckCircleIcon" />
            Kaydet
          </b-button>
        </div>
      </b-tab>
    </b-tabs>
    <!--
    <b-modal
      id="qrcode-modal"
      centered
      title="QR Kod Okuyucu"
      size="lg"
      ok-only
      ok-title="Kapat"
    >
      <b-card-text>
        QR Kod Okuyucu Buraya Gelecek.
      </b-card-text>
    </b-modal>
    -->
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardTitle, BCardText, BFormGroup, BFormInput, BFormTextarea, BButton, BTabs, BTab, BInputGroup, BInputGroupPrepend, BInputGroupAppend, BListGroup, BListGroupItem, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.tr'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/extensions
import { Turkish } from 'flatpickr/dist/l10n/tr.js'
// eslint-disable-next-line import/extensions
import customerCard from '@/views/Services/Components/CustomerCard'
// eslint-disable-next-line import/extensions
import machineCard from '@/views/Services/Components/MachineCard'
// eslint-disable-next-line import/extensions
import serviceCard from '@/views/Services/Components/ServiceCard'
// eslint-disable-next-line import/extensions
import travelCard from '@/views/Services/Components/TravelCard'

export default {
  name: 'Add',
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardText,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BTabs,
    BTab,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BListGroup,
    BListGroupItem,
    BModal,
    flatPickr,
    vSelect,
    customerCard,
    machineCard,
    serviceCard,
    travelCard,
    Cleave,
  },
  data() {
    return {
      submitStatus: false,
      machineKeyword: null,
      machineSelected: false,
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'TR',
        },
      },
      flatPickrConfig: {
        enableTime: true,
        dateFormat: 'd.m.Y H:i',
        locale: Turkish,
      },
      invoiced_status: null,
      work_type: null,
      job: null,
      city_out1: null,
      city_out2: null,
      district_out1: null,
      district_out2: null,
      city_return1: null,
      city_return2: null,
      district_return1: null,
      district_return2: null,
      districtsOut1: [],
      districtsOut2: [],
      districtsReturn1: [],
      districtsReturn2: [],
      worksData: [
        {
          sdate: null,
          edate: null,
        },
      ],
      finishedStatuses: [
        {
          id: 0,
          title: 'Devam Ediyor',
        },
        {
          id: 1,
          title: 'Tamamlandı',
        },
      ],
      finishedStatus: {
        id: 0,
        title: 'Devam Ediyor',
      },
      formData: {
        related_person: null,
        related_phone: null,
        notes: null,
        finished: null,
        finished_status: null,
        invoiced_status: null,
        work_type: null,
        job: null,
        city_out1: null,
        city_out2: null,
        district_out1: null,
        district_out2: null,
        sdatetime_out: null,
        edatetime_out: null,
        city_return1: null,
        city_return2: null,
        district_return1: null,
        district_return2: null,
        sdatetime_return: null,
        edatetime_return: null,
        id_cities_out1: null,
        id_cities_out2: null,
        id_districts_out1: null,
        id_districts_out2: null,
        id_cities_return1: null,
        id_cities_return2: null,
        id_districts_return1: null,
        id_districts_return2: null,
        id_service_statuses: 1,
        id_work_types: null,
        id_jobs: null,
        id_invoiced_statuses: null,
        id_machines: null,
        id_customers: null,
        id_customer_address: null,
      },
    }
  },
  computed: {
    machines() {
      return this.$store.getters['machines/getMachines']
    },
    machine() {
      const data = this.$store.getters['machines/getMachine']
      if (data.id) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.machineSelected = true
      }
      return data
    },
    invoicedStatuses() {
      return this.$store.getters['invoicedStatuses/getInvoiced_statuses']
    },
    workTypes() {
      return this.$store.getters['workTypes/getWork_types']
    },
    jobs() {
      return this.$store.getters['jobs/getJobs']
    },
    cities() {
      return this.$store.getters['cities/getCities']
    },
    districts() {
      return this.$store.getters['districts/getDistricts']
    },
    saveStatus() {
      return this.$store.getters['services/getServiceSaveStatus']
    },
  },
  watch: {
    machine(val) {
      if (val.id) {
        this.formData.id_machines = val.id
        this.formData.id_customers = val.id_customers
        this.formData.id_customer_address = val.id_customer_address
        this.getCustomer(val.id_customers)
        this.getCustomerAddress(val.id_customer_address)
      }
    },
    invoiced_status(val) {
      if (val.id) {
        this.formData.invoiced_status = val
        this.formData.id_invoiced_statuses = val.id
      }
    },
    work_type(val) {
      if (val.id) {
        this.formData.work_type = val
        this.formData.id_work_types = val.id
      }
    },
    job(val) {
      if (val.id) {
        this.formData.job = val
        this.formData.id_jobs = val.id
      }
    },
    finishedStatus(val) {
      this.formData.finished = val.id
      this.formData.finished_status = val
    },
    city_out1(val) {
      this.districtsOut1 = []
      if (val.id) {
        this.formData.city_out1 = val
        this.formData.id_cities_out1 = val.id
        this.districtsOut1 = this.filterDistricts(Number(val.id))
      }
    },
    city_out2(val) {
      if (val.id) {
        this.formData.city_out2 = val
        this.formData.id_cities_out2 = val.id
        this.districtsOut2 = this.filterDistricts(Number(val.id))
      }
    },
    district_out1(val) {
      if (val.id) {
        this.formData.district_out1 = val
        this.formData.id_districts_out1 = val.id
      }
    },
    district_out2(val) {
      if (val.id) {
        this.formData.district_out2 = val
        this.formData.id_districts_out2 = val.id
      }
    },
    city_return1(val) {
      this.districtsReturn1 = []
      if (val.id) {
        this.formData.city_return1 = val
        this.formData.id_cities_return1 = val.id
        this.districtsReturn1 = this.filterDistricts(Number(val.id))
      }
    },
    city_return2(val) {
      if (val.id) {
        this.formData.city_return2 = val
        this.formData.id_cities_return2 = val.id
        this.districtsReturn2 = this.filterDistricts(Number(val.id))
      }
    },
    district_return1(val) {
      if (val.id) {
        this.formData.district_return1 = val
        this.formData.id_districts_return1 = val.id
      }
    },
    district_return2(val) {
      if (val.id) {
        this.formData.district_return2 = val
        this.formData.id_districts_return2 = val.id
      }
    },
    saveStatus(val) {
      if (val.status === true) {
        this.formData = {
          related_person: null,
          related_phone: null,
          notes: null,
          finished: null,
          invoiced_status: null,
          work_type: null,
          job: null,
          city_out1: null,
          city_out2: null,
          district_out1: null,
          district_out2: null,
          sdatetime_out: null,
          edatetime_out: null,
          city_return1: null,
          city_return2: null,
          district_return1: null,
          district_return2: null,
          sdatetime_return: null,
          edatetime_return: null,
          id_cities_out1: null,
          id_cities_out2: null,
          id_districts_out1: null,
          id_districts_out2: null,
          id_cities_return1: null,
          id_cities_return2: null,
          id_districts_return1: null,
          id_districts_return2: null,
          id_service_statuses: 1,
          id_work_types: null,
          id_jobs: null,
          id_invoiced_statuses: null,
          id_machines: null,
          id_customers: null,
          id_customer_address: null,
        }
        this.worksData = [
          {
            sdate: null,
            edate: null,
          },
        ]
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Kayıt işleminiz başarılı!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Kayıt işleminiz yapılamadı.! Lütfen tekrar deneyiniz.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getInvoicedStatuses()
    this.getWorkTypes()
    this.getJobs()
    this.getCities()
    this.getDistricts()
  },
  methods: {
    searchMachine() {
      this.machineSelected = false
      const params = {
        or_like: {
          'machines.mcode': this.machineKeyword,
          'machines.project': this.machineKeyword,
          'machines.serial': this.machineKeyword,
          'customers.company': this.machineKeyword,
        },
      }
      this.$store.dispatch('machines/machinesList', params)
    },
    changeMachine() {
      this.machineSelected = false
      this.machineKeyword = null
      this.formData.id_machines = null
      this.formData.id_customers = null
      this.formData.id_customer_address = null
    },
    getMachine(id) {
      this.$store.dispatch('machines/machineView', id)
    },
    getCustomer(id) {
      this.$store.dispatch('customers/customerView', id)
    },
    getCustomerAddress(id) {
      this.$store.dispatch('customerAddress/customerAddressView', id)
    },
    getInvoicedStatuses() {
      this.$store.dispatch('invoicedStatuses/invoiced_statusesList', {
        select: [
          'invoiced_statuses.id AS id',
          'invoiced_statuses.title AS title',
        ],
      })
    },
    getWorkTypes() {
      this.$store.dispatch('workTypes/work_typesList', {
        select: [
          'work_types.id AS id',
          'work_types.title AS title',
        ],
      })
    },
    getJobs() {
      this.$store.dispatch('jobs/jobsList', {
        select: [
          'jobs.id AS id',
          'jobs.title AS title',
        ],
      })
    },
    getCities() {
      this.$store.dispatch('cities/citiesList', {
        select: [
          'cities.id AS id',
          'cities.title AS title',
        ],
      })
    },
    getDistricts() {
      this.$store.dispatch('districts/districtsList', {
        select: [
          'districts.id AS id',
          'districts.title AS title',
          'districts.id_cities AS id_cities',
        ],
      })
    },
    filterDistricts(id) {
      const { districts } = this
      return districts.filter(e => Number(e.id_cities) === id)
    },
    addWorkLine() {
      const { worksData } = this
      worksData.push({
        sdate: null,
        edate: null,
        workType: null,
        id_work_types: null,
      })
      this.worksData = worksData
    },
    removeWorkLines(index) {
      const { worksData } = this
      worksData.splice(index, 1)
      this.worksData = worksData
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.submitStatus = true
      this.formData.worksData = this.worksData
      this.$store.dispatch('services/serviceSave', this.formData)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
